import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

import Prism from 'prismjs';

import "index.scss"

import { library, dom } from '@fortawesome/fontawesome-svg-core'

import {
  faLinkedinIn, faGitlab, faGithub, faWordpress, faMastodon
} from '@fortawesome/free-brands-svg-icons'
library.add(faLinkedinIn, faGitlab, faGithub, faWordpress, faMastodon)

import {
  faEnvelope, faBars, faHeart, faExternalLinkSquareAlt, faUserCircle
} from '@fortawesome/free-solid-svg-icons'
library.add(faEnvelope, faBars, faHeart, faExternalLinkSquareAlt, faUserCircle)

dom.watch()

// Import all javascript files from src/_components
const componentsContext = require.context("bridgetownComponents", true, /.js$/)
componentsContext.keys().forEach(componentsContext)

// Import all stimulus controllers
const application = Application.start()
const context = require.context("./controllers", true, /.js$/)
application.load(definitionsFromContext(context))

// Import and register all TailwindCSS Components
import { Dropdown } from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown)

// Highlight all matching syntax
Prism.highlightAll();
